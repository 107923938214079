// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-component=Toggle] {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  overflow: hidden;
  background-color: #ececf1;
  color: #101010;
  height: 40px;
  border-radius: 1000px;
}
[data-component=Toggle]:hover {
  background-color: #d8d8d8;
}
[data-component=Toggle] div.label {
  position: relative;
  color: #666;
  transition: color 0.1s ease-in-out;
  padding: 0px 16px;
  z-index: 2;
  -webkit-user-select: none;
          user-select: none;
}
[data-component=Toggle] div.label.right {
  margin-left: -8px;
}
[data-component=Toggle] .toggle-background {
  background-color: #101010;
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  bottom: 0px;
  z-index: 1;
  border-radius: 1000px;
  transition: left 0.1s ease-in-out, width 0.1s ease-in-out;
}
[data-component=Toggle][data-enabled=true] div.label.right {
  color: #fff;
}
[data-component=Toggle][data-enabled=false] div.label.left {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/toggle/Toggle.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EAEA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,qBAAA;AAAF;AAEE;EACE,yBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,WAAA;EACA,kCAAA;EACA,iBAAA;EACA,UAAA;EACA,yBAAA;UAAA,iBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,yBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,UAAA;EACA,qBAAA;EACA,yDAAA;AAHJ;AAOI;EACE,WAAA;AALN;AAUI;EACE,WAAA;AARN","sourcesContent":["[data-component='Toggle'] {\n  position: relative;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  cursor: pointer;\n  overflow: hidden;\n\n  background-color: #ececf1;\n  color: #101010;\n  height: 40px;\n  border-radius: 1000px;\n\n  &:hover {\n    background-color: #d8d8d8;\n  }\n\n  div.label {\n    position: relative;\n    color: #666;\n    transition: color 0.1s ease-in-out;\n    padding: 0px 16px;\n    z-index: 2;\n    user-select: none;\n  }\n\n  div.label.right {\n    margin-left: -8px;\n  }\n\n  .toggle-background {\n    background-color: #101010;\n    position: absolute;\n    top: 0px;\n    left: 0px;\n    width: auto;\n    bottom: 0px;\n    z-index: 1;\n    border-radius: 1000px;\n    transition: left 0.1s ease-in-out, width 0.1s ease-in-out;\n  }\n\n  &[data-enabled='true'] {\n    div.label.right {\n      color: #fff;\n    }\n  }\n\n  &[data-enabled='false'] {\n    div.label.left {\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
